<template>
  <div class="term">
    <div class="icon-print">
      <img
        @click="print"
        src="@/assets/icon-print.png"/>
    </div>
    <div class="page-title">利用規約</div>
    <terms-detail/>
    <div>
      <div
        @click="backToMenu()"
        class="btn-img mt-4">
        <img src="@/assets/icon-menu.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import TermsDetail from '../../components/molecule/TermsDetail.vue'

export default {
  name: 'Term',
  components: {
    TermsDetail,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    print() {
      // https://jsfiddle.net/qeq0sy13/
      const divContents = document.getElementById("term-detail").innerHTML
      const content = window.open('', '', 'width=800')
      content.document.write('<html>')
      content.document.write('<body>')
      content.document.write(divContents)
      content.document.write('</body></html>')
      content.document.close()
      content.print()
      content.close()
    },
    backToMenu() {
      this.$router.push(`/config`)
    },
  }
}
</script>

<style lang="scss" scoped>
.agreement-lbl {
  margin-top: 10px;
  font-size: 29px;
  input {
    width: 24px;
    height: 24px;
    -moz-transform:	scale(1.4);
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
.agree-btn {
  margin-top: 20px;
  background: white;
  border: 1px solid;
  border-radius: 15px;
  padding: 5px 10px;
  width: 120px;
  cursor: pointer;
}
.icon-print {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
    width: 142px;
    margin: 10px 30px 10px 0;  
  }
}
</style>

